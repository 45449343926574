import { handleMobileMenuVisibility } from '../helpers/mobileMenu.js'

class MobileMenu {
	constructor(burger, sidebar) {
		this.burger = burger
		this.mobileMenu = sidebar
	}

	init() {
		this.burger.addEventListener('click', this.toggleMobileMenu.bind(this))
	}

	toggleMobileMenu(event) {
		const clickedBurger = event.target
		if (clickedBurger) handleMobileMenuVisibility(this.mobileMenu, clickedBurger)
	}
}

export default MobileMenu