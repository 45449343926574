import { setMinHeight } from './handleMinHeight.js'

export default function setContactPage() {
    initMinHeight()
}

function initMinHeight() {
	const contactBody = document.body

	setMinHeight(contactBody)
}