import {
	addClickEventsTo,
	preventClickEvent,
	closeAllAccordionContents,
	getClickedAccordionTitle,
	handleClickedAccordionTitle
} from '../helpers/accordion'

class Accordion {
	constructor(accordion) {
		this.accordion = accordion
	}

	init() {
		addClickEventsTo(this.accordion, this.handleAccordionContent.bind(this))
	}

	handleAccordionContent(event) {	
		preventClickEvent(event)

		const clickedAccordionTitle = getClickedAccordionTitle(event)

		if (clickedAccordionTitle) {
			closeAllAccordionContents(clickedAccordionTitle)
			handleClickedAccordionTitle(clickedAccordionTitle)
		}
	}
}

export default Accordion