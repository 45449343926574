import '../sass/style.scss'
import setPage from './libs/handlePage.js'
import setHomePage from './libs/handleHomePage.js'
import setServicesPage from './libs/handleServicesPage.js'
import setSingleWorkPage from './libs/handleSingleWorkPage.js'
import setContactPage from './libs/handleContactPage.js'
import setPrivacyPage from './libs/handlePrivacyPage.js'

setPage()

const bodyClassList = document.body.classList
const isHomePage = bodyClassList.contains('home-page')
const isServicesPage = bodyClassList.contains('services-page')
const isSingleWorkPage = bodyClassList.contains('single-work')
const isContactPage = bodyClassList.contains('contact-page')
const isPrivacyPage = bodyClassList.contains('privacy-page')

const mobileBreakpoint = 1025

if (isHomePage) setHomePage(mobileBreakpoint)
if (isServicesPage) setServicesPage()
if (isSingleWorkPage) setSingleWorkPage()
if (isContactPage) setContactPage()
if (isPrivacyPage) setPrivacyPage()