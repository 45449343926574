import { getHoverLangListItem, showSiblingLangListItem, hideShowingLangListItem } from '../helpers/lang.js'

class Lang {
	constructor(lang) {
		this.lang = lang
	}

	init() {
		this.lang.addEventListener('mouseover', this.showLang.bind(this))
		this.lang.addEventListener('mouseleave', this.hideLang.bind(this))
	}

	showLang(event) {
		const hoverLangListItem = getHoverLangListItem(event)		
		showSiblingLangListItem(hoverLangListItem)
	}

	hideLang() {
		hideShowingLangListItem(this.lang)
	}
}

export default Lang