export function setCursorPosition(cursor, event) {
	const cursorHeight = cursor.offsetHeight
	const cursorWidth = cursor.offsetWidth
	const cursorStyle = cursor.style
	cursorStyle.top = `${event.clientY - cursorHeight / 2}px`
	cursorStyle.left = `${event.clientX - cursorWidth / 2}px`
}

export function getCursorOverEl(event) {
	const overEl = event.target
	if (!overEl) return ''

	const overElName = overEl.nodeName

	const isCarousel = checkIfItsOverCarousel(overEl, overElName)
	if (isCarousel) return 'carousel'

	const isWork = checkIfItsOverWork(overEl, overElName)
	if (isWork) return 'work'

	const isLink = checkIfItsOverAnchor(overEl)
	if (isLink) return 'link'

	const isText = checkIfItsOverText(overElName)
	if (isText) return 'text'

	return ''
}

function checkIfItsOverCarousel(overEl, overElName) {	
	if (overElName !== 'IMG') return false
	let isCarouselEl = checkIfIsCarousel(overEl)
	while (overEl && !isCarouselEl) {
		overEl = overEl.parentElement
		isCarouselEl = checkIfIsCarousel(overEl)
	}
	const isCarousel = overEl ? true : false

	return isCarousel
}

function checkIfIsCarousel(overEl) {
	if (!overEl) return false
	const isCarouselEl = overEl.hasAttribute('data-carousel')
	return isCarouselEl
}

function checkIfItsOverWork(overEl, overElName) {
	if (overElName !== 'IMG' && overElName !== 'VIDEO') return false
	let isWorkEl = checkIfIsWork(overEl)
	while (overEl && !isWorkEl) {
		overEl = overEl.parentElement
		isWorkEl = checkIfIsWork(overEl)
	}
	const isWork = overEl ? true : false

	return isWork
}

function checkIfIsWork(overEl) {
	if (!overEl) return false
	const isWorkEl = overEl.classList.contains('work')
	return isWorkEl
}

function checkIfItsOverAnchor(overEl) {
	let elHasHref = checkIfIsLink(overEl)
	while (overEl && !elHasHref) {
		overEl = overEl.parentElement
		elHasHref = checkIfIsLink(overEl)
	}
	const isLink = overEl ? true : false

	return isLink
}

function checkIfIsLink(overEl) {
	if (!overEl) return false
	const isLinkEl = overEl.hasAttribute('href')
	return isLinkEl
}

function checkIfItsOverText(overElName) {
	const isText =
		overElName === 'P' ||
		overElName === 'SPAN' ||
		overElName === 'LI' ||
		overElName === 'H1' ||
		overElName === 'H2' ||
		overElName === 'H3' ||
		overElName === 'H4' ||
		overElName === 'H5' ||
		overElName === 'H6'

	return isText
}

export function setCursorAttribute(cursor, cursorOverElString) {
	cursor.removeAttribute('carousel')
	cursor.removeAttribute('work')
	cursor.removeAttribute('link')
	cursor.removeAttribute('text')
	if (cursorOverElString) cursor.setAttribute(cursorOverElString, '')
}