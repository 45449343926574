function getHeadlineElements(headline) {
    const is = headline.querySelector('[data-headline-is]')
    const text = headline.querySelector('[data-headline-text]')

    return { is, text }
}

export async function showIs(headline, timeout) {
    await wait(timeout * 0.25)
    const { is } = getHeadlineElements(headline)
    is.setAttribute('show', '')
}

export async function thenShowText(headline, timeout) {
    await wait(timeout * 0.5)
    const { text } = getHeadlineElements(headline)
	text.setAttribute('show', '')
}

export async function thenShowTexts(headline, texts, timeout) {
    const { text } = getHeadlineElements(headline)
    for (let i = 0; i < texts.length; i++) {
        text.textContent = texts[i]
        await wait(timeout * 0.7)
        text.setAttribute('hiding', '')
        await wait(timeout * 0.4)
        text.removeAttribute('hiding')

        if (i === texts.length - 1) i = 0
    }
}

function wait(timeout) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, timeout)
    })
}