export function getHoverLangListItem(event) {
	let hoverLangListItem = event.target
	while (hoverLangListItem && hoverLangListItem.nodeName !== 'LI') {
		hoverLangListItem = hoverLangListItem.parentElement
	}

	return hoverLangListItem
}

export function showSiblingLangListItem(hoverLangListItem) {
	if (hoverLangListItem) {
		const hasNextElementSibling = hoverLangListItem.nextElementSibling
		const siblingLangListItem =
			hasNextElementSibling ? hoverLangListItem.nextElementSibling : hoverLangListItem.previousElementSibling
		siblingLangListItem.setAttribute('show', '')
	}
}

export function hideShowingLangListItem(lang) {
	const langChildrenEl = lang.children
	Array.from(langChildrenEl).forEach(listItem => {
		const listItemHasShowAttribute = listItem.hasAttribute('show')
		if (listItemHasShowAttribute) listItem.removeAttribute('show')
	})
}