function getAccordionElements(accordion) {
	const accordionTitleEls = accordion.querySelectorAll('[data-accordion-item-title]')

	return {
		accordionTitleEls
	}
}

export function addClickEventsTo(accordion, handleAccordionContent) {
	const { accordionTitleEls } = getAccordionElements(accordion)

	accordionTitleEls.forEach(accordionTitleEl => {
		addClickEventTo(accordionTitleEl)
	})

	function addClickEventTo(accordionTitleEl) {
		accordionTitleEl.addEventListener('click', handleAccordionContent)
	}
}

export function preventClickEvent(event) {
	event.preventDefault()
}

export function closeAllAccordionContents(clickedAccordionTitle) {
	const accordionContents = document.querySelectorAll('[data-accordion-item-content]')
	const accordionPlusMinus = document.querySelectorAll('[data-accordion-item-plus-minus] span')
	const { clickedAccordionContent, clickedAccordionPlusMinus } = getClickedAccordionTitleData(clickedAccordionTitle)

	accordionContents.forEach(accordionContent => {
		const isNotTheOneClicked = !clickedAccordionContent.isEqualNode(accordionContent)
		if (isNotTheOneClicked) accordionContent.removeAttribute('open')
	})

	accordionPlusMinus.forEach(accordionPlusMinus => {
		const isNotTheOneClicked = !clickedAccordionPlusMinus.isEqualNode(accordionPlusMinus)
		if (isNotTheOneClicked) accordionPlusMinus.removeAttribute('checked')
	})
}

export function getClickedAccordionTitle(clickEvent) {
	const clickedAccordionTitle = clickEvent.target
	const isClickedAccordionTitle = clickedAccordionTitle && clickedAccordionTitle.hasAttribute('[data-accordion-item-title]')

	while (isClickedAccordionTitle) {
		clickedAccordionTitle = clickedAccordionTitle.parentElement
	}

	return clickedAccordionTitle
}

function getClickedAccordionTitleData(clickedAccordionTitle) {
	const clickedAccordionPlusMinus = clickedAccordionTitle.querySelector('[data-accordion-item-plus-minus] span')
	const clickedAccordionContent = clickedAccordionTitle.nextElementSibling

	return { clickedAccordionPlusMinus, clickedAccordionContent }
}

function accordionAlreadyOpen(clickedAccordionTitle) {
	const { clickedAccordionContent } = getClickedAccordionTitleData(clickedAccordionTitle)
	const isAccordionAlreadyOpen = clickedAccordionContent.hasAttribute('open')
	return isAccordionAlreadyOpen
}

export function handleClickedAccordionTitle(clickedAccordionTitle) {
	const isAccordionAlreadyOpen = accordionAlreadyOpen(clickedAccordionTitle)
	const { clickedAccordionPlusMinus, clickedAccordionContent } = getClickedAccordionTitleData(clickedAccordionTitle)

	if (isAccordionAlreadyOpen) {
		clickedAccordionPlusMinus.removeAttribute('checked')
		clickedAccordionContent.removeAttribute('open')
	} else {
		clickedAccordionPlusMinus.setAttribute('checked', '')
		clickedAccordionContent.setAttribute('open', '')
	}
}