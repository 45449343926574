export function handleMobileMenuVisibility(mobileMenu, clickedBurger) {
    clickedBurger.checked ? openMobileMenu(mobileMenu) : closeMobileMenu(mobileMenu)
}

function openMobileMenu(mobileMenu) {
    mobileMenu.setAttribute('open', '')
}

function closeMobileMenu(mobileMenu) {
    setClosingTo(mobileMenu)
    waitTransitionAndThenClose(mobileMenu)
}

function setClosingTo(mobileMenu) {
    mobileMenu.setAttribute('closing', '')
}

function waitTransitionAndThenClose(mobileMenu) {
    mobileMenu.addEventListener('transitionend', () => {
        mobileMenu.removeAttribute('closing')
        mobileMenu.removeAttribute('open')
    }, { once: true })
}