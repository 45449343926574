import { setMinHeight } from './handleMinHeight.js'

export default function setPrivacyPage() {
    initMinHeight()
}

function initMinHeight() {
	const privacyBody = document.body

	setMinHeight(privacyBody)
}