export function setMinHeight(... elements) {
	elements.forEach(el => {
		el.style.minHeight = `${window.document.documentElement.clientHeight}px`
	})
}

export function unsetMinHeight(... elements) {
	elements.forEach(el => {
		el.style.minHeight = ''
	})
}