import {
	showFirstView,
	showView,
	ifExistsGetCursor,
	clickOverImage,
	increaseOrDecreaseCounter,
	clickInFirstHalf,
	setLeftCursor,
	setRightCursor
} from '../helpers/carousel.js'

class Carousel {
	constructor(carousel) {	
		this.carousel = carousel
		this.setInterval = null
		this.interval = 3000
		this.isMoving = false
		this.counter = 1
	}

	init() {
		this.start()
		this.setEvents()
	}

	start() {
		this.setFirstView()
		this.startInterval()
	}

	setFirstView() {
		const currentCounter = showFirstView(this.carousel, this.counter)
		this.counter = currentCounter
	}

	startInterval() {
		this.setInterval = setInterval(async () => {
			this.counter++
			const rightDirection = 'right'
			const currentCounter = this.handleAnimation(this.counter, rightDirection)
			this.counter = currentCounter
		}, this.interval)
	}

	async handleAnimation(counter, direction) {
		this.isMoving = true
		this.counter = await showView(this.carousel, counter, direction)
		this.isMoving = false
	}

	setEvents() {
		const cursor = ifExistsGetCursor()
		if (cursor) {
			this.addMouseEvents(cursor)
			this.addClickEvent(cursor)
		}
	}

	addMouseEvents(cursor) {
		this.carousel.addEventListener('mouseenter', this.setActiveCursor.bind(this, cursor))
		this.carousel.addEventListener('mousemove', this.setLeftOrRightCursor.bind(this, cursor))
		this.carousel.addEventListener('mouseleave', this.removeActiveCursor.bind(this, cursor))
	}

	setActiveCursor(cursor) {
		cursor.setAttribute('active', '')
	}

	setLeftOrRightCursor(cursor, event) {
		const isClickInFirstHalf = clickInFirstHalf(this.carousel, event)
		isClickInFirstHalf ? setLeftCursor(cursor) : setRightCursor(cursor)
	}

	removeActiveCursor(cursor) {
		cursor.removeAttribute('active')
	}

	addClickEvent(cursor) {
		this.carousel.addEventListener('click', this.onClick.bind(this, cursor))
	}

	async onClick(cursor, event) {
		if (this.isMoving) {
			event.preventDefault()
			return
		}

		const isClickOverImage = clickOverImage(event)
		if (isClickOverImage) {
			event.preventDefault()
			if (this.setInterval) clearInterval(this.setInterval)
			const { counter, direction } = increaseOrDecreaseCounter(cursor, this.counter)
			const currentCounter = this.handleAnimation(counter, direction)
			this.counter = currentCounter
			this.startInterval()
		}
	}
}

export default Carousel