import { initCursor, setCursor } from '../libs/handleCursor.js'
import { initMobileMenu, setMobileMenu } from '../libs/handleMobileMenu.js'
import setLang from '../libs/handleLang.js'

const cursor = initCursor()
const mobileMenu = initMobileMenu()

export default function setPage() {
    setResizable()
    setLang()
    setOnResizeEvent()
}

function setResizable() {
    setCursor(cursor)
    setMobileMenu(mobileMenu)
}

function setOnResizeEvent() {
    window.addEventListener('resize', () => setResizable())
}