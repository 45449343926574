import Carousel from '../modules/Carousel.js'

const carouselEl = document.querySelector('[data-carousel]')

export function setCarousel() {
	if (carouselEl) {
		const carousel = new Carousel(carouselEl)
		return carousel
	}
	return null
}

export function initCarousel(carousel) {
	carousel.init()
}