import Accordion from '../modules/Accordion.js'

export default function setServicesPage() {
	initAccordions()
}

function initAccordions() {
	const accordionEls = document.querySelectorAll('[data-accordion]')
	accordionEls.forEach(accordionEl => initAccordion(accordionEl))
}

function initAccordion(accordionEl) {
	const accordion = new Accordion(accordionEl)
	accordion.init()
}