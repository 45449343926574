import SingleWorkHero from '../modules/SingleWorkHero.js'
import { setMinHeight } from './handleMinHeight.js'

export default function setSingleWorkPage() {
	initHeroSection()
	initMinHeight()
}

function initHeroSection() {
	const singleWorkHeroEl = document.querySelector('[data-hero-single-work]')
	const singleWorkHero = new SingleWorkHero(singleWorkHeroEl)
	singleWorkHero.init()
}

function initMinHeight() {
	const heroEl = document.querySelector('[data-hero-single-work-image]')

	setMinHeight(heroEl)
}