import {
	fetchBackgroundImages,
	setBackgroundImage,
	setHeadlineMarginTop
} from '../helpers/singleWorkHero.js'

class SingleWorkHero {
	constructor(hero) {
		this.hero = hero
		this.backgroundImagesId = null
	}

	init() {
		this.handleBackground()
		this.updateHeadlineMarginTop()
		this.addResizeEvent()
	}

	async handleBackground() {
		this.backgroundImagesId = await fetchBackgroundImages()
		setBackgroundImage(this.backgroundImagesId)
	}

	updateHeadlineMarginTop() {
		setHeadlineMarginTop(this.hero)
	}

	addResizeEvent() {
		window.addEventListener('resize', () => this.changeOnResize())
	}

	changeOnResize() {
		this.updateHeadlineMarginTop()
		setBackgroundImage(this.backgroundImagesId)
	}
}

export default SingleWorkHero