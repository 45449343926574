import Cursor from '../modules/Cursor.js'

const carouselData = []
const duration = 750

function getCarouselElements(carousel) {
	const carouselListItems = carousel.querySelectorAll('li')
	const carouselFirstItem = carouselListItems[0]
	const carouselFirstItemParagraph = carouselFirstItem.querySelector('p')
	const carouselFirstItemAnchor = carouselFirstItem.querySelector('a')
	const dataCarouselOverlayEl = carousel.querySelector('[data-carousel-overlay]')
	const dataCarouselTitleEl = carousel.querySelector('[data-carousel-title]')
	const dataCarouselTotalEl = carousel.querySelector('[data-carousel-total]')
	const dataCarouselCounterEl = carousel.querySelector('[data-carousel-counter]')

	return {
		carouselListItems,
		carouselFirstItemParagraph,
		carouselFirstItemAnchor,
		dataCarouselOverlayEl,
		dataCarouselTitleEl,
		dataCarouselTotalEl,
		dataCarouselCounterEl
	}
}

export function showFirstView(carousel, counter) {
	storeCarouselData(carousel)
	setDataCarouselTotalEl(carousel)
	showCarouselOverlay(carousel)
	prepareCarouselForAnimation(carousel)
	const currentCounter = handleDynamicDataCarousel(carousel, counter)
	return currentCounter
}

function storeCarouselData(carousel) {
	const carouselEls = carousel.querySelectorAll('li')	
	carouselEls.forEach(li => {
		const { paragraph, anchor } = getCurrentItemDataEl(li)
		const listItemData = { paragraph, anchor }
		carouselData.push(listItemData)
	})
}

function setDataCarouselTotalEl(carousel) {
	const { carouselListItems } = getCarouselElements(carousel)
	const { dataCarouselTotalEl } = getCarouselElements(carousel)
	dataCarouselTotalEl.innerText = carouselListItems.length
}

function showCarouselOverlay(carousel) {
	const { dataCarouselOverlayEl } = getCarouselElements(carousel)
	dataCarouselOverlayEl.setAttribute('show', '')
}

function prepareCarouselForAnimation(carousel) {
	const carouselList = carousel.querySelector('ul')
	moveLastElAtTheBeginning(carouselList)
	changeCarouselViewPort(carouselList)
}

function changeCarouselViewPort(carouselList) {
	carouselList.style.position = 'relative'
	carouselList.style.left = 'calc(-100% - 4px)'
}

function handleDynamicDataCarousel(carousel, counter) {
	const currentCounter = setCounterWithBoundaries(carousel, counter)
	setDataCarouselTitleEl(carousel, currentCounter)
	setDataCarouselCounter(carousel, currentCounter)
	return currentCounter
}

function getCurrentItemDataEl(currentItemEl) {
	const currentItemParagraphEl = currentItemEl.querySelector('p')
	const currentItemAnchorEl = currentItemEl.querySelector('a')

	return {
		paragraph: currentItemParagraphEl.innerText,
		anchor: currentItemAnchorEl.getAttribute('href')
	}
}

function setCounterWithBoundaries(carousel, counter) {
	const { carouselListItems } = getCarouselElements(carousel)
	const carouselListItemLength = carouselListItems.length

	if (counter > carouselListItemLength) return 1
	if (counter < 1) return carouselListItemLength

	return counter
}

async function setDataCarouselTitleEl(carousel, counter) {
	const { dataCarouselTitleEl } = getCarouselElements(carousel)
	const carouselItemData = carouselData[counter - 1]	
	const itemParagraph = carouselItemData.paragraph
	const itemAnchor = carouselItemData.anchor
	dataCarouselTitleEl.style.opacity = 0
	await waitAnimation(duration / 2)
	dataCarouselTitleEl.style.opacity = 1
	dataCarouselTitleEl.innerText = itemParagraph
	dataCarouselTitleEl.setAttribute('href', itemAnchor)
}

function setDataCarouselCounter(carousel, counter) {
	const { dataCarouselCounterEl } = getCarouselElements(carousel)	
	dataCarouselCounterEl.innerText = counter
}

export async function showView(carousel, counter, direction) {
	const currentCounter = handleDynamicDataCarousel(carousel, counter)
	await animateView(carousel, direction)
	return currentCounter
}

async function animateView(carousel, direction) {
	const carouselList = carousel.querySelector('ul')
	switch (direction) {
		case 'right':
			transitionToRight(carouselList, duration)
			await waitAnimation(duration)
			moveFirstElAtTheEnd(carouselList)
			break
		case 'left':
			transitionToLeft(carouselList, duration)
			await waitAnimation(duration)
			moveLastElAtTheBeginning(carouselList)
			break
	}
}

function transitionToRight(carouselList, duration) {
	carouselList.style.transition = `transform .${duration}s ease-in-out`;
	carouselList.style.transform = 'translateX(calc(-100% - 4px))'
}

function moveFirstElAtTheEnd(carouselList) {
	const firstElement = carouselList.querySelector('li:first-child')
	carouselList.removeChild(firstElement)
	carouselList.appendChild(firstElement)
	resetTransition(carouselList)
}

function transitionToLeft(carouselList, duration) {
	carouselList.style.transition = `transform .${duration}s ease-in-out`;
	carouselList.style.transform = 'translateX(calc(100% + 4px))'
}

function moveLastElAtTheBeginning(carouselList) {
	const lastElement = carouselList.querySelector('li:last-child')
	carouselList.removeChild(lastElement)
	carouselList.insertBefore(lastElement, carouselList.firstChild)
	resetTransition(carouselList)
}

function resetTransition(carouselList) {
	carouselList.style.transition = 'transform 0s';
	carouselList.style.transform = ''
}

function waitAnimation(ms) {
	const waitPromise = new Promise(res => setTimeout(res, ms))
	return waitPromise
}

export function ifExistsGetCursor() {
	const cursorEl = document.querySelector('[data-cursor]')
	const cursor = new Cursor(cursorEl)
	return cursor.cursor
}

export function clickOverImage(event) {
	const clickedEl = event.target
	const clickedElIsCarouselTitle = clickedEl.hasAttribute('data-carousel-title')
	const clickedElIsSeeAllWorks = clickedEl.hasAttribute('data-carousel-all-works')

	if (clickedElIsCarouselTitle || clickedElIsSeeAllWorks) return false
	return true
}

export function increaseOrDecreaseCounter(cursor, counter) {
	let direction = ''
	if (cursor.hasAttribute('left')) {
		counter--
		direction = 'left'
	}
	if (cursor.hasAttribute('right')) {
		counter++
		direction = 'right'
	}
	return { counter, direction }
}

export function clickInFirstHalf(carousel, event) {
	const carouselBoundingRect = carousel.getBoundingClientRect()
	const xPositionInsideCarousel = event.clientX - carouselBoundingRect.left
	const halfCarouselWidth = carouselBoundingRect.width / 2

	return xPositionInsideCarousel < halfCarouselWidth
}

export function setLeftCursor(cursor) {
	cursor.setAttribute('left', '')
	cursor.removeAttribute('right')
}

export function setRightCursor(cursor) {
	cursor.setAttribute('right', '')
	cursor.removeAttribute('left')
}