import MobileMenu from '../modules/MobileMenu.js'

export function initMobileMenu() {
	const burgerMenuEl = document.querySelector('[data-burger]')
	const mobileMenuEl = document.querySelector('[data-sidebar]')
	const mobileMenu = new MobileMenu(burgerMenuEl, mobileMenuEl)
	return mobileMenu
}

export function setMobileMenu(mobileMenu) {	
	mobileMenu.init()
}