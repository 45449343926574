import Cursor from '../modules/Cursor.js'

export function initCursor() {
	const cursorEl = document.querySelector('[data-cursor]')
	const cursor = new Cursor(cursorEl)
	return cursor
}

export function setCursor(cursor) {
	isTouchScreen() ? cursor.remove() : cursor.add()
}

function isTouchScreen() {
	if ('maxTouchPoints' in navigator) {
		return navigator.maxTouchPoints > 0
	} else if ('msMaxTouchPoints' in navigator) {
		return navigator.msMaxTouchPoints > 0
	} else {
		let mQ = window.matchMedia && matchMedia('(pointer:coarse)')
		if (mQ && mQ.media === '(pointer:coarse)') {
			return !!mQ.matches
		} else if ('orientation' in window) {
			return true; // deprecated, but good fallback
		} else {
			// only as a last resort, fall back to user agent sniffing
			let UA = navigator.userAgent;
			return (
				/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
				/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
			)
		}
	}
}